import React, { useEffect, useState, useContext } from "react";
import { authContext } from "../../account/auth/AuthProvider";
import configuration from "react-global-configuration";

const Checkpoint = (props) => {
  const {
    hanldeActiveStackTab,
    activeStackTab,
    termsCondition,
    handleTermsConditons,
    stakingBalance,
  } = props;

  const { auth } = useContext(authContext);

  const [checklistItems, setChecklistitems] = useState([
    {
      name: "connect with MetaMask",
      status: auth.authStatus,
    },
    {
      name: "XFUEL Available to unstake",
      status: stakingBalance > 0 ? true : false,
    },
    {
      name: "BNB Available in wallet",
      status: auth.ethBalance > 0 ? true : false,
    },
    {
      name: "Eligible to stake",
      status: auth.authStatus && stakingBalance > 0 && auth.ethBalance > 0,
    },
  ]);

  const [isValid, setIsValid] = useState(
    auth.authStatus &&
      stakingBalance > 0 &&
      auth.ethBalance > 0 &&
      termsCondition
  );

  useEffect(() => {
    setIsValid(
      auth.authStatus &&
        stakingBalance > 0 &&
        auth.ethBalance > 0 &&
        termsCondition
    );
    setChecklistitems([
      {
        name: "connect with MetaMask",
        status: auth.authStatus,
      },
      {
        name: "XFUEL Available to unstake",
        status: stakingBalance > 0 ? true : false,
      },
      {
        name: "BNB Available in wallet",
        status: auth.ethBalance > 0 ? true : false,
      },
      {
        name: "Eligible to unstake",
        status: auth.authStatus && stakingBalance > 0 && auth.ethBalance > 0,
      },
    ]);
  }, [auth, termsCondition]);

  return (
    <>
      <div className="stack-tab">
        <h4 className="text-center text-capitalize letter-3">checkpoint</h4>
        <p className="text-center">
          The Following Conditions must be met to proceed
        </p>
        <div className="checkpoint-card-wrapper">
          {checklistItems.map((list, index) => (
            <div className="checkpoint-card" key={index}>
              <div className="d-flex  justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <h6 className="text-capitalize">{list.name}</h6>
                  {index == 1 && (
                    <p className="mt-0 letter-no-spacing text-justify">
                      {Number(stakingBalance).toLocaleString(undefined, {
                        maximumFractionDigits: 5,
                      })}
                    </p>
                  )}
                  {index == 2 && (
                    <p className="mt-0 letter-no-spacing text-justify">
                      {Number(auth.ethBalance).toLocaleString(undefined, {
                        maximumFractionDigits: 5,
                      })}
                    </p>
                  )}
                </div>

                <div class="radio radio--style4 mb-0 mt-2">
                  <label>
                    <input
                      type="checkbox"
                      name="optionsRadios3"
                      checked={list.status}
                      readOnly
                    />
                    <span class="circle"></span>
                    <span class="check"></span>
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div class="checkbox checkbox--style4 text-center">
          <label className="whitecolor">
            <input
              type="checkbox"
              name="optionsCheckboxes3"
              checked={termsCondition}
              onClick={() => handleTermsConditons()}
              readOnly
            />
            <span class="checkbox-material">
              <span class="check"></span>
            </span>
            I have read Terms And Conditions
          </label>
        </div>
      </div>
      <div className="d-flex  justify-content-center align-items-center">
        <div className="col-lg-6 col-xs-10 col-sm-10 col-md-8">
          <div className="fuelx_unstack_card_wrapper">
            <ul className="fuelx_unstack_list">
              <li className="item">
                <div className="row ">
                  <div className="col-lg-8 col-xs-8 col-sm-8 col-md-8 d-flex align-items-center">
                    <p className="mb-0">Staked less than 2 weeks</p>
                  </div>
                  <div className="col-lg-4 col-xs-4 col-sm-4 col-md-4">
                    <h4 className="m-0">{configuration.get("configData.staked_lt_2_weeks", "25.00")}%</h4>
                  </div>
                </div>
              </li>
              <li className="item">
                <div className="row ">
                  <div className="col-lg-8 col-xs-8 col-sm-8 col-md-8 d-flex align-items-center">
                    <p className="mb-0">less than 4 weeks</p>
                  </div>
                  <div className="col-lg-4 col-xs-4 col-sm-4 col-md-4">
                    <h4 className="m-0">{configuration.get("configData.staked_lt_4_weeks", "15.00")}%</h4>
                  </div>
                </div>
              </li>
              <li className="item">
                <div className="row ">
                  <div className="col-lg-8 col-xs-8 col-sm-8 col-md-8 d-flex align-items-center">
                    <p className="mb-0">less than 6 weeks</p>
                  </div>
                  <div className="col-lg-4 col-xs-4 col-sm-4 col-md-4">
                    <h4 className="m-0">{configuration.get("configData.staked_lt_6_weeks", "10.00")}%</h4>
                  </div>
                </div>
              </li>
              <li className="item">
                <div className="row ">
                  <div className="col-lg-8 col-xs-8 col-sm-8 col-md-8 d-flex align-items-center">
                    <p className="mb-0">less than 8 weeks</p>
                  </div>
                  <div className="col-lg-4 col-xs-4 col-sm-4 col-md-4">
                    <h4 className="m-0">{configuration.get("configData.staked_lt_8_weeks", "5.00")}%</h4>
                  </div>
                </div>
              </li>
              <li className="item">
                <div className="row ">
                  <div className="col-lg-8 col-xs-8 col-sm-8 col-md-8 d-flex align-items-center">
                    <p className="mb-0">8 weeks or more</p>
                  </div>
                  <div className="col-lg-4 col-xs-4 col-sm-4 col-md-4">
                    <h4 className="m-0">{configuration.get("configData.staked_gte_8_weeks", "0.00")}%</h4>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex  justify-content-center align-items-center mt-4">
        <div className="col-lg-6 col-xs-10 col-sm-10 col-md-8">
          <div className="fuelx-stack-infotext">
            {/* <p>you have staked for 20 days. your current fee is <strong>25%</strong>.</p> */}
            <p>
              Fees will be deducted at the withdraw stage. Please click 'Next' if
              you wish to proceed.
            </p>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
        <div className="stackbuttonWrapper">
          <button
            type="button"
            className={`stackPrevButton ${
              activeStackTab <= 1 ? "disabled" : ""
            }`}
            disabled={activeStackTab <= 1}
            onClick={() => hanldeActiveStackTab((prev) => prev - 1)}
          >
            <svg class="woox-icon">
              <use xlinkHref="#icon-arrow-left"></use>
            </svg>
          </button>
          <button
            type="button"
            className={`stackPrevButton ml-5 ${!isValid ? "disabled" : ""}`}
            disabled={!isValid}
            onClick={() => hanldeActiveStackTab((prev) => prev + 1)}
          >
            <svg class="woox-icon">
              <use xlinkHref="#icon-arrow-right"></use>
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default Checkpoint;
